<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a href="/home">Home</a></li>
      <li class="breadcrumb-item"><a href="/activities/daylist">Atividades dos dia</a></li>
      <li class="breadcrumb-item active" aria-current="page">Lista de presença</li>
    </ol>
  </nav>
  <div class="container my-3 p-3 bg-light rounded box-shadow">
    <div>
      <div class="form-row">
        <div class="form-group col-md-9">
          <label for="name">Nome</label>
          <input type="text" class="form-control" id="name" placeholder="Nome" v-model="activity.name" disabled>
        </div>
        <div class="form-group col-md-3">
          <label>Data</label>
          <input type="date" class="form-control" v-model="activityDate" >
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-1">
          <input type="checkbox" id="Dom" value="0" v-model="checkedDays" disabled >
          <label class="ml-1" for="Dom">Dom.</label>
        </div>
        <div class="form-group col-md-1">
          <input type="checkbox" id="Seg" value="1" v-model="checkedDays" disabled >
          <label class="ml-1" for="Seg">Seg.</label>
        </div>
        <div class="form-group col-md-1">
          <input type="checkbox" id="Ter" value="2" v-model="checkedDays" disabled >
          <label class="ml-1" for="Ter">Ter.</label>
        </div>
        <div class="form-group col-md-1">
          <input type="checkbox" id="Qua" value="3" v-model="checkedDays" disabled >
          <label class="ml-1" for="Qua">Qua.</label>
        </div>
        <div class="form-group col-md-1">
          <input type="checkbox" id="Qui" value="4" v-model="checkedDays" disabled >
          <label class="ml-1" for="Qui">Qui.</label>
        </div>
        <div class="form-group col-md-1">
          <input type="checkbox" id="Sex" value="5" v-model="checkedDays" disabled >
          <label class="ml-1" for="Sex">Sex.</label>
        </div>
        <div class="form-group col-md-1">
          <input type="checkbox" id="Sab" value="6" v-model="checkedDays" disabled >
          <label class="ml-1" for="Sab">Sab.</label>
        </div>
      
        <div class="form-group col-md-2">
          <input type="time" class="form-control" id="name" placeholder="Nome" v-model="activity.starttime" disabled>
        </div>
        <div class="form-group col-md-2">
          <input type="time" class="form-control" id="name" placeholder="Nome" v-model="activity.endtime" disabled>
        </div>
      </div>

      <div class="table-responsive">
        <table class="table table-striped table-sm">
          <thead>
            <tr>
              <th class="d-none">#</th>
              <th>Nome</th>
              <th>Status</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="record in presenceList" :key="record.id">
              <td class="d-none"> {{ record.id }}</td>
              <td> {{ record.associate }}</td>
              <td> {{ this.mapStatus[record.status] }}</td>
              <td>
                <button type="button" class="btn btn-dark ml-1" data-toggle="tooltip" title="Presente" @click="setStatus(record.id, 'present')" >
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar-check" viewBox="0 0 16 16">
                    <path d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
                  </svg>
                </button>
                <button type="button" class="btn btn-dark ml-1" data-toggle="tooltip" title="Ausente"  @click="setStatus(record.id, 'absent')" >
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar2-x" viewBox="0 0 16 16">
                    <path d="M6.146 8.146a.5.5 0 0 1 .708 0L8 9.293l1.146-1.147a.5.5 0 1 1 .708.708L8.707 10l1.147 1.146a.5.5 0 0 1-.708.708L8 10.707l-1.146 1.147a.5.5 0 0 1-.708-.708L7.293 10 6.146 8.854a.5.5 0 0 1 0-.708z"/>
                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z"/>
                    <path d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4z"/>
                  </svg>
                </button>
                <button type="button" class="btn btn-dark ml-1" data-toggle="tooltip" title="Justificado" @click="setStatus(record.id, 'justified')" >
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar-minus" viewBox="0 0 16 16">
                    <path d="M5.5 9.5A.5.5 0 0 1 6 9h4a.5.5 0 0 1 0 1H6a.5.5 0 0 1-.5-.5z"/>
                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
                  </svg>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        
      </div>

      <div style="float: right;">
        <router-link class="btn btn-default" style="margin-left:10px;" to="/activities">Fechar</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { sAlert } from '../utils/jalerts';
import ApiAxios from '../../services/api-axios';
import moment from 'moment';

export default {
  data() {
    return {
			presenceList: [],
      checkedDays: [],
			strFields: '',
      activity: {},
      activityDate: moment().format('YYYY-MM-DD'),
			page: 1,
			size: 100,
			pages: 0,
			sort: 'name',
			param: '',
      mapStatus: {
        uninformed: '',
        present: 'Presente',
        absent: 'Ausente',
        justified: 'Justificado',
      }
		};
  },
  watch: {
    activityDate() {
      this.fetchGetList();
    }
  },
  computed: {
		filters() {
			if (this.param) return { name: this.param };

			return {};
		},
	},
  methods: {
		callSearch(e) {
			if (e.keyCode === 13) this.search();
		},
    async fetchGetData() {
			ApiAxios.get(`activities/${this.$route.params.id}`)
        .then((res) => {
          this.activity = res.data.activity;
          if (this.activity.days) this.checkedDays = this.activity.days;
          this.$root.loadingVisible = false;
        })
        .catch((e) => {
          this.$root.loadingVisible = false;
          if (e.response.status == 403) {
            this.$router.back();
            return sAlert({ type: 'warning', text: 'Ops, ' + e.response.data });  
          }
          sAlert({ type: 'danger', text: 'Ops, houve um erro: ' + e.response.data });
        });
		},
		async fetchGetList() {
			this.$root.loadingVisible = true;
			ApiAxios.get(`activities/${this.$route.params.id}/presenceList`, { activitydate: this.activityDate })
			.then((res) => {
        this.presenceList = res.data.presenceList;
				// this.pages = res.data.totalPages;
				this.$root.loadingVisible = false;
			})
			.catch((e) => {
				this.$root.loadingVisible = false;
				if (e.response.status == 403) {
					this.$router.back();
					return sAlert({ type: 'warning', text: 'Ops, ' + e.response.data });  
				}
				sAlert({ type: 'danger', text: 'Ops, houve um erro: ' + e.response.data });
			});
		},
		search() {
			if(this.page === 1) { return this.fetchGetList(); }
			this.page = 1;
		},
    setStatus(id, status) {
			let that = this;
			this.$root.loadingVisible = true;
      ApiAxios.post(`activityAssociatePresences`, { activityAssociatePresence: {
          activity_associate_id : id,
          status,
          activitydate: this.activityDate
        }})
        .then(() => {
          this.$root.loadingVisible = false;
          that.fetchGetList();
        })
        .catch((e) => {
          this.$root.loadingVisible = false;
          if (e.response && e.response.status == 403) {
            return sAlert({ type: 'warning', text: 'Ops, ' + e.response.data });  
          }
          sAlert({ type: 'danger', text: 'Ops, houve um erro: ' + e.response.data });
        });  
				
			
		},
	},
	created () {
    this.fetchGetData();
		this.fetchGetList();
	},
}
</script>  
